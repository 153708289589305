import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchRecordsKeys, recordsColumns } from "./config/index";
import { RainApi, WhitelistApi } from "@/api";
export default {
  name: "rainRecords",
  components: {},
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      formloading: false,
      users: [],
      // 用户列表
      creators: [],
      // 创建者
      timer: null,
      columns: recordsColumns,
      searchKeys: searchRecordsKeys,
      isConfirmVisible: false,
      subLoading: false

      // action: "add", // 操作:add,edit,delete
      // editId: undefined,
      // modalVisible: false,
    };
  },

  mixins: [Mixin, TableMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      var _this = this;
      return function (key) {
        switch (key) {
          case "userid":
            return _this.users;
          case "taskcreatedby":
            return _this.creators;
        }
      };
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {},
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    onSearch: function onSearch(field, val) {
      var _this2 = this;
      if (!val) return;
      this.formloading = true;
      field == "userid" ? this.users = [] : this.creators = [];
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(function () {
        WhitelistApi.searchUsernameV2({
          name: val
        }).then(function (data) {
          field == "userid" ? _this2.users = data : _this2.creators = data;
        }).catch(function () {}).finally(function () {
          _this2.formloading = false;
        });
      }, 500);
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this3.selectedRowKeys = [];
          _this3.loading = true;
          var params = _objectSpread({
            page: _this3.page,
            size: _this3.size
          }, values);
          if (params.dates) {
            params.starttime = values.dates[0].format("YYYY-MM-DD HH:mm:ss"); // // HH:mm:ss
            params.endtime = values.dates[1].format("YYYY-MM-DD HH:mm:ss"); // // HH:mm:ss
            delete params.dates;
          }
          RainApi.logList(_objectSpread({}, params)).then(function (res) {
            _this3.loading = false;
            _this3.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this3.total = (res === null || res === void 0 ? void 0 : res.total) || _this3.total;
          }).catch(function () {
            _this3.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this3.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    // handleAction(action, record) {
    //   this.action = action;
    //   this.editId = record?.taskid || "";
    //   if (action == "edit" || action == 'check') {
    //     this.modalVisible = true;
    //     // this.source = record;
    //   } else if (action == ADD) {
    //     this.modalVisible = true;
    //     // this.source = {};
    //   } else {
    //     return;
    //   }
    // },
    /*******表单相关start********/
    handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    } /*******表单相关end********/
  }
};